import React from 'react';
import MainLayout from '../layouts/main';
import { Typography } from '@material-ui/core';
import { SEO } from '../components/SEO';
import PageHead from '../components/PageHead';
import {
  FeatureAffiliateDashboard,
  FeatureCardGrid,
  FeatureConversionHeatmaps,
  FeatureIntegrations,
  FeatureLinkManagement,
  FeatureLinkGenerator,
  FeatureRevenueAttribution,
  FeatureRevisionTracking,
  FeatureTeams,
  FeatureContentAnalytics,
  FeatureSegmentation,
  FeatureAuthors,
  FeaturePayouts,
  FeatureTrafficSources,
  FeatureRoles,
  FeatureAffiliateApis,
  FeatureExports,
  FeatureCustomIntegrations,
  FeatureRates
} from '../components/FeatureGrid';
import { DemoButton } from '../components/DemoButton';

export default () => {
  return (
    <MainLayout>
      <div>
        <SEO
          pathname="/product/"
          title="Affiliate Tracking for Publishers · Affilimate Features"
          description=""
        />
        <PageHead>
          <Typography
            variant="h3"
            gutterBottom
            component="h1"
            style={{
              maxWidth: '600px',
              margin: '0 auto 36px',
              fontWeight: 900
            }}
          >
            Affiliate growth tools for leading content publishers
          </Typography>
          <Typography
            variant="body1"
            color="textSecondary"
            style={{ maxWidth: '600px', margin: '24px auto 0' }}
          >
            Tap into the most powerful commerce growth platform used by leading
            content publishers and at scale. Find revenue opportunities in your
            existing content, links, and partnerships with tools like these.
          </Typography>
        </PageHead>
        <Typography
          variant="h5"
          gutterBottom
          component="h2"
          style={{
            fontWeight: 900,
            textAlign: 'center'
          }}
        >
          Content insights and optimization
        </Typography>
        <Typography
          variant="body1"
          color="textSecondary"
          style={{
            maxWidth: '510px',
            margin: '24px auto 12px',
            textAlign: 'center'
          }}
        >
          Editor-friendly tools that teams will actually adopt and use to
          improve content performance.
        </Typography>
        <FeatureCardGrid>
          <FeatureContentAnalytics />
          <FeatureSegmentation />
          <FeatureRevenueAttribution />
          <FeatureConversionHeatmaps />
          <FeatureTrafficSources />
          <FeatureRevisionTracking />
        </FeatureCardGrid>
        <Typography
          variant="h5"
          gutterBottom
          component="h2"
          style={{
            fontWeight: 900,
            textAlign: 'center'
          }}
        >
          Workflow and collaboration
        </Typography>
        <Typography
          variant="body1"
          color="textSecondary"
          style={{
            maxWidth: '510px',
            margin: '24px auto 12px',
            textAlign: 'center'
          }}
        >
          Improve efficiency and eliminate errors with team collaboration and
          link management tools.
        </Typography>
        <FeatureCardGrid>
          <FeatureAuthors />
          <FeatureLinkGenerator />
          <FeatureRates />
          <FeatureLinkManagement />
          <FeatureTeams />
          <FeatureRoles />
        </FeatureCardGrid>
        <Typography
          variant="h5"
          gutterBottom
          component="h2"
          style={{
            fontWeight: 900,
            textAlign: 'center'
          }}
        >
          Reporting and finance
        </Typography>
        <Typography
          variant="body1"
          color="textSecondary"
          style={{
            maxWidth: '510px',
            margin: '24px auto 12px',
            textAlign: 'center'
          }}
        >
          Track your revenue, commissions, and payouts with ease.
        </Typography>
        <FeatureCardGrid>
          <FeatureAffiliateDashboard />
          <FeatureAffiliateApis />
          <FeaturePayouts />
          <FeatureExports />
          <FeatureIntegrations />
          <FeatureCustomIntegrations />
        </FeatureCardGrid>
        <Typography
          variant="h5"
          gutterBottom
          component="h2"
          style={{
            fontWeight: 900,
            textAlign: 'center'
          }}
        >
          But wait, there's more
        </Typography>
        <Typography
          variant="body1"
          color="textSecondary"
          style={{
            maxWidth: '510px',
            margin: '24px auto 12px',
            textAlign: 'center'
          }}
        >
          Discover more ways publishers are growing with Affilimate by booking a
          demo with our team today.
          <br />
          <br />
          <DemoButton variant="contained" />
        </Typography>
        <br />
        <br />
      </div>
    </MainLayout>
  );
};
